<template>
  <div>
    llaalallala
    <canvas id="myChart" onload="console.log('som')"></canvas>
  </div>
</template>
<script>
/*const labels = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
];

const data = {
  labels: labels,
  datasets: [{
    label: 'Vývoj hodnoty NASDAQ 100',
    backgroundColor: '#008456',
    borderColor: '#008456',
    data: [0, 10, 5, 2, 20, 30, 45],
  }]
};

const config = {
  type: 'line',
  data: data,
  options: {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Vývoj ceny za posledný'
      }
    }
  },
};*/

//import Chart from 'chart.js/auto'

export default {
  name: 'app',
  components: {

  },
  mounted() {
    /*new Chart(
        document.getElementById('myChart'),
        config
    );*/
  },
  data() {
    return {
    }
  }
}

</script>