<template>
  <div class="px-1">
    <div v-bind:class="{'card': true,  'text-white': true, 'card-green': ticker.side === 'pos', 'card-red': ticker.side === 'neg'}" @click="moveToParent">
      <div class="card-body">
        <div class="row">
          <div class="col-6 text-nowrap">
            <div class="placeholder-glow" v-if="!ticker"><span class="placeholder col-7"></span></div>
            <h6 class="card-title" v-if="ticker">{{ ticker.name }}</h6>
          </div>
          <div class="col-6 text-end">
            <div class="placeholder-glow" v-if="!ticker.last_price"><span class="placeholder col-7"></span></div>
            <span class="fw-bolder" v-if="ticker.last_price">{{ ticker.last_price }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="placeholder-glow" v-if="!ticker.side"><span class="placeholder col-12"></span></div>
            <i class="fa-solid fa-caret-up fs-2" v-if="ticker.side === 'pos'"></i>
            <i class="fa-solid fa-caret-down fs-2" v-if="ticker.side === 'neg'"></i>
          </div>
          <div class="col-8 text-end">
            <div class="placeholder-glow" v-if="!ticker.change_in_eur || !ticker.change_percentage"><span class="placeholder col-10"></span></div>
            <div class="" v-if="ticker.change_in_eur || ticker.change_percentage"><span class="MarketCard-changesPts">{{ ticker.change_in_eur }}</span> | <span class="MarketCard-changesPct">{{ ticker.change_percentage }}</span></div>
          </div>
        </div>
        <div class="placeholder-glow" v-if="!ticker.last_update"><span class="placeholder col-8"></span></div>
        <small class="" v-if="ticker.last_update">Aktualizácia: {{  ticker.last_update }}</small>
      </div>
    </div>
  </div>


  <div id="du_iframe_graph" style="display:none; position: fixed; top: 0; left: 0; z-index: 1040; width: 100vw; height: 100vh; background-color: rgba(0, 0, 0, 0.4); backdrop-filter: blur(15px);">
    <div style="position: fixed; top: 50%; left: 50%;   -ms-transform: translate(-50%, -50%);  transform: translate(-50%, -50%); max-height: 500px; max-width: 900px; width: 100%; height: 100%; background-color: white; border-radius: 5px 5px 5px 5px">
      <button style="position: absolute; right: 20px; top: 20px; padding: 6px; background-color: #5c636a; border-color: #5c636a; color: white; border-radius: 5px" onclick="document.getElementById('du_iframe_graph').remove()">x zavrieť</button>
      <iframe src="https://financnykompas.datauniverse.sk/iframe-chart" id="iframe_chart" title="Stocks widget" style="overflow: hidden;" height="100%" width="100%"></iframe>
    </div>
  </div>

</template>

<script>
export default {
  name: 'ticker-view',
  data() {
    return {
    }
  },
  props: {
    ticker: {
      name: String,
      last_price: String,
      side: String,
      change_in_eur: String,
      change_percentage: String,
      last_update: String
    }
  },
  methods: {
    redirect() {
      window.top.location.href = "http://www.example.com";
    },
    moveToParent() {
      var element = document.getElementById("du_iframe_graph")
      element.style.display = "block";
      window.top.document.body.append(element);
    },
  },
  mounted() {
  }
}
</script>

<style scoped>
  .card {
    width: 190px;
    height: 105px;
    font-size: 75%;
  }

  .card-green {
    background-color: #008456;
  }

  .card-red {
    background-color: #ce2b2b;
  }
</style>
