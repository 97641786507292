import { createRouter, createWebHistory } from 'vue-router'
import Test from "@/components/test/Test";
import IframePanel from "@/components/iframe-panel/Iframe";
import IframeChart from "@/components/iframe-chart/Iframe";

const routes = [
  {
    path: '/test',
    component: Test,
  },
  {
    path: '/iframe-panel',
    name: 'iframe-panel',
    component: IframePanel
  },
  {
    path: '/iframe-chart',
    name: 'iframe-chart',
    component: IframeChart
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router
