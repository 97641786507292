<template>
  aaaaaaaaaaaaaaaaaaaaaaaaaaaa
  <div class="container">
    <iframe src="https://financnykompas.datauniverse.sk/iframe-panel" id="iframe_panel" title="Stocks widget" style="overflow: hidden;" width="100%" onchange="document.getElementById('iframe_panel').style.height = document.getElementById('iframe_panel').contentWindow.document.body.scrollHeight + 'px'"></iframe>
  </div>
</template>

<script>
export default {
  name: 'ticker-view',
  props: {
  }
}
</script>

<style scoped>
</style>
