<template>
  <router-view/>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  methods: {
    goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
    }
  }
}
</script>

<style>
</style>
