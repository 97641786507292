<template>
  <div class="iframe-view">
    <div class="row  border-bottom">
      <div class="col-lg-6 py-1">
        <a href="#" @click="this.changeOption(this.stocks)" v-bind:class="{'pe-3 fw-bold  px-md-3 text-decoration-none text-muted': true, 'active': this.option === stocks}">AKCIE</a>
        <a href="#" @click="this.changeOption(this.commodities)" v-bind:class="{'pe-3 fw-bold px-md-3 text-decoration-none text-muted': true, 'active': this.option === commodities}">KOMODITY</a>
        <a href="#" @click="this.changeOption(this.currencies)" v-bind:class="{'pe-3 fw-bold px-md-3 text-decoration-none text-muted': true, 'active': this.option === currencies}">MENY</a>
        <a href="#" @click="this.changeOption(this.cryptocurrencies)" v-bind:class="{'pe-3 fw-bold px-md-3 text-decoration-none text-muted': true, 'active': this.option === cryptocurrencies}">KRYPTOMENY</a>
        <a href="#" @click="this.changeOption(this.etffonds)" v-bind:class="{'pe-3 fw-bold px-md-3 text-decoration-none text-muted': true, 'active': this.option === etffonds}">ETF FONDY</a>
      </div>
      <div class="col-lg-6">
      </div>
    </div>
    <div class="d-flex ticker-list text-nowrap table-responsive py-3">
      <div  v-for="ticker in this.testData" v-bind:key="ticker.name">
        <Ticker :ticker="ticker"/>
      </div>
    </div>
  </div>
  <Modal/>
</template>

<script>

import Ticker from './Ticker';
import Modal from './Modal';
import  { stocks, commodities, currencies, cryptocurrencies, etffonds} from '../../constants'
const testData = [
  {
    'name': 'NASDAQ 100',
    'last_price': '123 023.23 €',
    'side': 'pos',
    'change_in_eur': '+ 24.34 €',
    'change_percentage': '+ 0.54 %',
    'last_update': '04. 08. 2022 10:23',
  },
  {
    'name': 'S&P 500',
    'last_price': '1 023.23 €',
    'side': 'neg',
    'change_in_eur': '- 24.34 €',
    'change_percentage': '- 0.54 %',
    'last_update': '04. 08. 2022 10:23',
  },
  {
    'name': 'S&P 500',
    'last_price': '1 023.23 €',
    'side': 'neg',
    'change_in_eur': '- 24.34 €',
    'change_percentage': '- 0.54 %',
    'last_update': '04. 08. 2022 10:23',
  },
  {
    'name': 'NASDAQ 100',
    'last_price': '123 023.23 €',
    'side': 'pos',
    'change_in_eur': '+ 24.34 €',
    'change_percentage': '+ 0.54 %',
    'last_update': '04. 08. 2022 10:23',
  },
  {
    'name': 'S&P 500',
    'last_price': '1 023.23 €',
    'side': 'neg',
    'change_in_eur': '- 24.34 €',
    'change_percentage': '- 0.54 %',
    'last_update': '04. 08. 2022 10:23',
  },
  {
    'name': 'NASDAQ 100',
    'last_price': '123 023.23 €',
    'side': 'pos',
    'change_in_eur': '+ 24.34 €',
    'change_percentage': '+ 0.54 %',
    'last_update': '04. 08. 2022 10:23',
  },
  {
    'name': 'S&P 500',
    'last_price': '1 023.23 €',
    'side': 'neg',
    'change_in_eur': '- 24.34 €',
    'change_percentage': '- 0.54 %',
    'last_update': '04. 08. 2022 10:23',
  },
  {
    'name': 'S&P 500',
    'last_price': '1 023.23 €',
    'side': 'neg',
    'change_in_eur': '- 24.34 €',
    'change_percentage': '- 0.54 %',
    'last_update': '04. 08. 2022 10:23',
  },
]

export default {
  name: 'iframe-view',
  components: {
    Ticker,
    Modal
  },
  data() {
    return {
      option: stocks,
      stocks: stocks,
      commodities: commodities,
      currencies: currencies,
      cryptocurrencies: cryptocurrencies,
      etffonds: etffonds,
      testData: testData
    }
  },
  methods: {
    changeOption(option) {
      this.option = option;
    }
  },
  props: {
  }
}
</script>

<style scoped>
.ticker-list::-webkit-scrollbar {
  height: 5px;
}

.ticker-list::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 0px rgba(0, 0, 0, 0);
}

.ticker-list::-webkit-scrollbar-thumb {
  height: 5px;
  background-color: #6c757d;
}

.ticker-list::-webkit-scrollbar-thumb:hover {
  background-color: #6c757d;
}

.ticker-list::-webkit-scrollbar:vertical {
  display: none;
}

.active {
  color: #005594 !important;
  text-decoration: underline !important;
}
</style>
